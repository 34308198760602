const ons    = require("onsenui")

let project  = require("./project")
let view     = require("./view")
let session  = require("./session")
let stage    = require("./stage")

const loadProject = function(){
  project.cargarComicsTodos('idDivGaleria');
}

const loadSession = function(){
  session.loadSession();
}

const loadStage = function(){
  stage.loadStage();
}

const loadView = function (){
  view.loadView();
}
const playItem = function (indice){
  return view.playItem(indice)
}

module.exports = {
  loadProject,
  loadSession,
  loadView,
  loadStage,
  playItem
}