const axios  = require("axios")
const helpers= require("./helpers")
const env = process.env.API_HOST;
const get_CATEGORIAS = env + '/projects';

const cargarComicsTodos = function (id) {
  let tagDiv = document.getElementById(id);
  tagDiv.innerHTML = "";
  axios.get(get_CATEGORIAS, helpers.token ).then((response) => {
    if (response.status == 200) {
      if (response.data.length != 0) {
        let res = response.data;
        for (var i = 0; i < res.length; i++) {
          let element = res[i];
          tagDiv.innerHTML = tagDiv.innerHTML +
            `
              <div class="card" style="border-radius: 3px;  padding: 0px; ">
              <a href="stage.html?doc=${element.id}">
                  <img src="${element.icon}" class="center list-item__center" style="width: 100%; height: auto;  background-color: #2c2c2c; border-radius: 2px;">
              </a>
              <div class="content">
                <h2 class="card__title" style="padding-left: 0.70em; padding-right: 0.70em; font-size: 18px; font-weight: bold;">
                    ${element.name}
                </h2>
                
                <div class="card__content" style="color: #636567;  padding-left: 1.0em; padding-right: 1.0em; padding-bottom: 0.250em; text-align: justify; ">
                    ${element.description}
                </div>
                
                <div style="padding-left: 1.0em; padding-right: 1.0em; margin-top: 0.50em; margin-bottom: 0.50em;">
                    <a class="button" style="float: right; padding-top:  0.50em; padding-bottom: 0.50em; padding-left: 1.250em; padding-right: 1.250em; margin: 0em; font-size: 12px; line-height: 14px;" href="stage.html?project=${element.id}"> 
                      Entrar
                    </a>
                </div>
              </div>
            </div>
            `;
        }
      }
    } else {
      helpers.messageErrorOns(ons, 'Comics - Error: ' + response.status, 'warning')
      //window.location.href = 'index.html';
    }
  })
  .catch((error) => {
    helpers.messageErrorOns(ons, 'Comics: ' + error, 'danger');
    //window.location.href = 'index.html';
  });
}

module.exports = {
  cargarComicsTodos,
}