const axios  = require("axios")
const ons    = require("onsenui")
const helpers= require("./helpers")

const env = process.env.API_HOST;
const get_CATEGORIAS = env + '/projects/';

const loadStage = function () {
  var urlParams = new URLSearchParams(window.location.search);
  var idDoc = urlParams.get('project');
  if (typeof idDoc === 'undefined' || idDoc == null) {
    window.location = 'project.html';
  } else {
    cargarDoc(idDoc);
  }
}

const cargarDoc = function (id) {
  let tagDiv = document.getElementById("styleDiv_a");
  tagDiv.innerHTML = "";

  axios.get(get_CATEGORIAS + id + "/stages", helpers.token )
    .then((response) => {
      if (response.status == 200) {
        if (response.data.length != 0) {
          for (var obj of response.data.stages) {
            tagDiv.innerHTML = tagDiv.innerHTML +
              `<div class="flex flex-col w-full md:w-5/12 pb-5">
                  <div class="h-56 bg-white bg-no-repeat bg-center bg-contain shadow-sm" style="background-image:url('/static/images/l1.png')">
                  </div>
                  <div class="px-4 py-4 bg-gray-400 flex items-end justify-between">
                    <a href="#" class="font-semibold leading-tight text-2xl text-primary">
                      ${obj.name}
                    </a>
                    <div class="overflow-hidden flex justify-end">
                      <a href=session.html?stage=${obj.id}&project=1 class="m-1 bg-primary text-white hover:bg-secondary text-white-800 font-bold py-1 px-2">
                        <span><i class="fa fa-sign-in"></i>Continuar</span>
                      </a>
                    </div>
                  </div>
                </div>`;
          }
        }
      }
      else {
        helpers.messageErrorOns('Comics - Error: ' + response.status, 'warning')
       // window.location.href = 'index.html';
      }
    })
    .catch((error) => {
      helpers.messageErrorOns('Comics: ' + error, 'danger');
      //window.location.href = 'index.html';
    });
}


module.exports = {
  loadStage
}