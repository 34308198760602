const axios  = require("axios")
const ons    = require("onsenui")
const helpers= require("./helpers")

const env = process.env.API_HOST;
const get_CATEGORIAS = env + '/projects/';

const loadSession = function () {
  let urlParams = new URLSearchParams(window.location.search);
  let idProject = urlParams.get('project');
  let idStage = urlParams.get('stage');

  if (typeof idProject === 'undefined' || idProject == null || typeof idStage === 'undefined' || idStage == null) {
    window.location = 'project.html';
  } else {
    load(idProject, idStage);
  }
}

load = function (idProject, idStage) {
  let tagDiv = document.getElementById("styleDiv_a");
  tagDiv.innerHTML = "";

  axios.get(get_CATEGORIAS + idProject + "/stages/" + idStage + "/sessions", helpers.token )
    .then((response) => {
      let data = "";
      if (response.status == 200) {
        if (response.data.length != 0) {
          data = ' <main><div class="md:flex md:-mx-4 p-5">';
          for (var obj of response.data.stages.sessions) {
            if (obj.labyrinthine == 1) {
              continue;
            }
            data = data +`
            <div class="w-full mb-2 md:w-1/2 md:mx-4 border rounded shadow-sm">
              <a href="#" class="mb-4">
                <img class="rounded" src="/static/images/h1.png">
              </a>
              <div class="px-2 py-2 bg-white">
                <div>
                  <a href="#" class="font-semibold leading-tight text-2xl text-primary">
                    ${obj.name}
                  </a>
                </div>
                <hr class="border-gray-200 my-1 border-bottom-none" style="border-top-width:0">
                <p class="text-gray-700">
                  ${obj.description}
                </p>
                <div class='flex text-gray-700 text-sm '>
                  <div class="pr-3">Octubre 18, 2020</div>
                </div>
                <div class="overflow-hidden flex justify-end">
                  <a href="view.html?project=${idProject}&stage=${idStage}&session=${obj.id}">
                    <button class="m-1 bg-primary text-white hover:bg-secondary text-white-800 font-bold py-1 px-2">
                      <span><i class="fa fa-sign-in"></i>ENTRAR</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>`;
          }
          data = data + '</div></main>';
        }
        tagDiv.insertAdjacentHTML('beforebegin', data);
      }
      else {
        helpers.messageErrorOns('Comics - Error: ' + response.status, 'warning')
      //  window.location.href = 'index.html';
      }
    })
    .catch((error) => {
      helpers.messageErrorOns('Comics: ' + error, 'danger');
    //  window.location.href = 'index.html';
    });
}

module.exports = {
  loadSession
}