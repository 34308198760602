const ons = require("onsenui")

const redirectEndVignette = function () {
   let urlParams = new URLSearchParams(window.location.search);
   let idProject = urlParams.get('project');
   let idSession = urlParams.get('session');
   window.location = `stage.html?project=${idProject}&session=${idProject}`;
}

const token = {
   headers: { 
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer 123456`,
   }
};

const getParameter = function (parameter){
   return new URLSearchParams(window.location.search).get(parameter);
}

const messageErrorOns = function (mensaje, tipo = null) {
   let app = {};
   app.notificacion = function (mensaje, tipo = null) {
      let color = 0;
      if (tipo === 'success') {
         color = 'green'
      }
      if (tipo === 'warning') {
         color = 'yellow'
      }
      if (tipo === 'danger') {
         color = 'red'
      }
      if (tipo === null) {
         color = 'blue'
      }
      ons.notification.toast(mensaje, { timeout: 1500, animation: 'fall' });
   };
   app.notificacion(mensaje, tipo);
}

module.exports = {
   redirectEndVignette,
   getParameter,
   token,
   messageErrorOns
}
//${sessionStorage.getItem('token')}