const axios = require("axios")
const ons = require("onsenui")
const howl = require("howler")
const card_template = require("./cards/template")
const helpers= require("./helpers")

const env = process.env.API_HOST;
const get_CATEGORIAS = env + '/projects/';

let ITEMS = [];
let ITEMS_TIEMPOS = [];
var ITEMS_AUDIOS = [];

const getAudios = function (){
  return ITEMS_AUDIOS
}

const loadView = function () {
  let urlParams = new URLSearchParams(window.location.search);
  let idProject = urlParams.get('project');
  let idSession = urlParams.get('session');
  let idStage = urlParams.get('stage');
  let idPage = urlParams.get('page') || 1 ;

  if (typeof idProject === 'undefined' || idProject == null || typeof idSession === 'undefined' || idSession == null) {
   // window.location = 'project.html';
  } else {
    cargarVinetasComic(idProject, idSession, idStage);
  }
}

const cargarVinetasComic = function (idProject, idSession, idStage) {
  ITEMS = [];
  ITEMS_TIEMPOS = [];
  ITEMS_AUDIOS = [];

  let res_NORMAL = [];
  let res_Q = [];
  let tags = '';

  var tag = document.getElementById('myCarousel');
  axios.get(get_CATEGORIAS + idProject + "/stages/" + idStage + "/sessions/" + idSession + "/items", helpers.token )
    .then(response => {
      if (response.status == 200) {
        let RES = response.data.stages.sessions.items;
        document.getElementById('idTagSpanDoc').innerHTML = response.data.stages.name;
        document.getElementById('idTagSpanDocIdi').innerHTML = response.data.stages.sessions.name;
        
        for (var i = 0; i < RES.length; i++) {
          let element = RES[i];

          if (element.type == "vignette"){
            res_NORMAL.push(element)
            let obj = element.vignetes.files;
            let activity_i = card_template.template_card_normal(obj.file_image, obj.file_audio, i);
            var onsItem = ons.createElement(activity_i);
            tag.appendChild(onsItem);
            
            if (obj.file_audio == null) {
              ITEMS_TIEMPOS.push(4);
              ITEMS_AUDIOS.push(null);
            } else {
              let sound = new Howl({
                src: [obj.file_audio],
                html5: true,
                buffer: true,
                format: "mp3",
                onload: function () {
                  ITEMS_TIEMPOS.push(obj.duration);
                }
              });
              ITEMS_AUDIOS.push(sound);
            }
          }

          if(element.type == "question"){
            if(element.questions.type == "simple"){
              let question_simple = card_template.template_card_question_simple(element.questions.question);
              var onsItem = ons.createElement(question_simple);
              tag.appendChild(onsItem);
            }

            if(element.questions.type == "multiple_one"){
              let question_simple = card_template.template_card_question_multiple_one(element.questions);
              var onsItem = ons.createElement(question_simple);
              tag.appendChild(onsItem);
            }

            if(element.questions.type == "multiple_choises"){
              let question_simple = card_template.template_card_question_multiple_choises(element.questions);
              var onsItem = ons.createElement(question_simple);
              tag.appendChild(onsItem);
            }

            if(element.questions.type == "yesNo"){
              let question_simple = card_template.template_card_question_multiple_one(element.questions);
              var onsItem = ons.createElement(question_simple);
              tag.appendChild(onsItem);
            }

            if (element.questions.type == "labyrinthine") {
              let question_simple = card_template.template_card_question_labyrinthine(element.questions);
              var onsItem = ons.createElement(question_simple);
              tag.appendChild(onsItem);
            }
          }
        }
        //let activity_i = card_template.template_card_score();
        //var onsItem = ons.createElement(activity_i);
        //tag.appendChild(onsItem);
        card_template.template_card_progress_bar();
      } else {
        mensajeNotificacion('Vinetas - comics: Error: ' + response.status, 'danger');
        //window.location.href = 'index.html';
      }
    })
    .then(response => {
      /* if (ITEMS_AUDIOS.length != 0) {
        if (ITEMS_AUDIOS[0] != null) {
          var sound_0 = new Howl({
            src: [ITEMS_AUDIOS[0]._src],
            autoplay: true
          });
          ITEMS_AUDIOS[0] = sound_0;
          sound_0.play();
        }
      }*/
    })
    .catch((error) => {
      helpers.mensajeNotificacion('Vinetas - comics 2: ' + error, 'danger');
    //  window.location.href = 'index.html';
      console.log(error);
    });
    //tag.appendChild()
}

function playItem(indice) {
  let ITEMS_AUDIOS = getAudios();
  var lim = ITEMS_AUDIOS.length;
  if (indice <= lim - 1 && ITEMS_AUDIOS[indice] != null) {
    for (var i = 0; i < ITEMS_AUDIOS.length; i++) {
      if (ITEMS_AUDIOS[i] != null) {
        ITEMS_AUDIOS[i].stop();
      }
    }
    let ele = ITEMS_AUDIOS[indice];
    ele.play();
  }
  else {
    console.log('Estado Audio: ', ITEMS_AUDIOS[indice]);
  }
}

function setIndexLoad() {
  var readyState = setInterval(function () {
    if (document && document.readyState === 'complete') {
      clearInterval(readyState);
      readyState = null;
      setIndex();
    }
  }, 10);
}

function setIndex() {
  let index = helpers.getParameter('page');
  let carousel = document.getElementById('myCarousel');
  carousel.first();
  let arrayObjProgress = document.getElementsByTagName('ons-progress-bar');
  if (index > arrayObjProgress.length) {
    carousel.first();
    index = 0;
  }
  document.getElementById('myCarousel').setActiveIndex(index);
}
ons.ready(function() {
  var carousel = document.addEventListener('postchange', function(event) {
    console.log('Changed to ' + event.activeIndex);
    var indice = event.activeIndex;
    playItem(indice);
    var tag = document.getElementById('id_barra');
    var arrayObjProgress = tag.getElementsByTagName('ons-progress-bar');
    for (var i = 0; i < arrayObjProgress.length; i++) {
        var objProgress = arrayObjProgress[i];
        var element =  objProgress.getElementsByClassName('progress-bar__primary')[0];
        element.style.backgroundColor = '#afafaf'; 
    }

    var tag = document.getElementById('id_barra');
    var arrayObjProgress = tag.getElementsByTagName('ons-progress-bar');
    var objProgress = arrayObjProgress[ indice ];
    var element =  objProgress.getElementsByClassName('progress-bar__primary')[0];
    element.style.backgroundColor = '#0076ff';
    var idTagDivNumber = document.getElementById('idTagDivNumber');
    idTagDivNumber.innerHTML = (indice+1);
  });

  carousel = document.addEventListener('refresh', function (event) {
    setIndexLoad();
  });
});

module.exports = {
  loadView,
  playItem
}