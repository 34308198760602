const template_card_score = function () {
    return `
<ons-carousel-item>
    <div style="background-color: #eeebcf; padding: 0; margin: 0; position: absolute; width: 100%; height: 100%;">
        <div style="background-color: rgb(0,0,0,0.010); position: absolute; top:  0em; left: 0em; z-index: 110; width: 100%;  height: 10%;">
            <div>
            </div>
            <div> &nbsp;&nbsp;&nbsp;</div>
            <div style="width: 100%; height: 100vh; position: relative;  top: 0em; left: 0em; z-index: 100; display: flex; vertical-align: middle; align-items: center; justify-content: center;">
                <!-- init score -->
                <div class="card card--material" style="margin: 1.0em;  padding: 1.0em;  display: block;">
                    <div class="card__title card--material__title" style="padding: 0.25em;">
                        Califica la historia
                    </div>
                    <div style="display: inline-block; margin-left: 29px; margin-bottom: 0.250em; width: 100%;">
                        <div class="rating">
                            <span data-rating=5>☆</span><span data-rating=4>☆</span><span data-rating=3>☆</span><span data-rating=2>☆</span><span data-rating=1>☆</span>
                        </div>
                    </div>
                </div>
                <!--  end score -->
            </div>
            <div style="background-color: rgb(0,0,0,0.010); position: absolute; bottom: 0em; left: 0em; z-index: 110; width: 100%; height:10%;">
                &nbsp;&nbsp;&nbsp;
            </div>
        </div>
</ons-carousel-item>`;
}

const template_card_normal = function (file_image, file_audio, indice) {
    let tag_audio = ``;
    if (file_audio != null) {
        tag_audio = `<button style="background: transparent; border: none; color: #707070;" onclick="library.playItem(${indice});">  
                        <i class="zmdi zmdi-play-circle-outline zmdi-hc-lg "></i>						                
                    </button>`;
    }

    let element = `<ons-carousel-item>
        <div style="background-color: #efefef; padding-top: 0.50em; padding-bottom: 0.0em; margin-top: 0.0em; margin-bottom: 0.0em; margin-left: 0.0em; margin-right: 0.0em; position: absolute; width: 100%; height: 100%;">
            <div style="background-color: rgb(0,0,0,0.010); position: absolute; top: 0%; left: 0%; z-index: 110; width: 100%;  height: 10%; display: inline-block; margin: 0.0em;  padding: 0.0em;"></div>
            <div style="width: 100%;  height: 87%; position: relative; top: 5%; left: 0%; z-index: 100; display: flex; vertical-align: middle; align-items: center; justify-content: center; border-radius: 3px; margin: 0.0em;  padding: 0.0em; text-align: center;">
                <img src="${file_image}" style="margin-top: 0.0em; margin-bottom: 0.0em; background-color: #ffffff; text-align: center;" class="estilo_imagen_responsive">
            </div>
            <div style="background-color: rgb(0,0,0,0.010); position: absolute; bottom: 0%; left: 0%; z-index: 110; width: 100%; height: 5%; display: inline-block; margin-top: 0.0em; margin-bottom: 1.0em; padding: 0.0em; text-align: center;">
                ${tag_audio}
            </div>
        </div>
        </ons-carousel-item>`;
    return element;
}

const template_card_progress_bar = function () {
    var numberItems = document.querySelector('ons-carousel').itemCount;
    let progressBar = (100 / numberItems);
    let elements = ``;

    for (let item = 0; item < numberItems; item++) {
        elements += `<ons-col width="${progressBar}%" style=" padding-left: 1px; padding-right: 1px;" onclick="procesoIrItem(${item})">
                        <ons-progress-bar value="100" onclick="procesoIrItem(${item})" ></ons-progress-bar>
                    </ons-col>`;
    }
    let tagDivBarra = document.getElementById('id_barra');
    tagDivBarra.innerHTML = `<ons-row  style="margin-top: 0.5em; margin-bottom: 0.5em;"> ${elements} </ons-row> `;
}

const template_card_question_simple = function (question) {
    return `<ons-carousel-item>
            <div style="background-color: #efefef; padding-top: 0.50em; padding-bottom: 0.0em; margin-top: 0.0em; margin-bottom: 0.0em; margin-left: 0.0em; margin-right: 0.0em; position: absolute; width: 100%; height: 100%;">
                <div style="background-color: rgb(0,0,0,0.010); position: absolute; top: 0%; left: 0%; z-index: 110; width: 100%;  height: 10%; display: inline-block; margin: 0.0em;  padding: 0.0em;"></div>
                
                <div style="width: 100%;  height: 87%; position: relative; top: 5%; left: 0%; z-index: 100; display: flex; vertical-align: middle; align-items: center; justify-content: center; border-radius: 3px; margin: 0.0em;  padding: 0.0em; text-align: center;">
                    <div class="card card--material" style="margin: 0.50em; display: block; padding-left: 2.0em; padding-right: 2.0em;">
                        <div class="card__title card--material__title">
                            ${question}
                        </div>
                        
                        <div class="card__content card--material__content"></div>
                        
                        <div style="display: block; border: 1px solid #afafaf; border-radius: 5px; margin-top: 0.50em; margin-bottom: 0.50em; margin-left: -0.50em; margin-right: -0.50em; padding: 0.0em;">
                            <textarea class="textarea textarea--transparent" style="width: 100%;  padding: 0.20em;" rows="5" placeholder="Respuesta"></textarea>
                        </div>
                        
                        <div style="display: block; width: 100%;">
                            <button style="margin: 1px; padding: 1.0em; border: 1px solid #198ce7; border-radius: 10px; background-color: #ffffff; width: 100%;" onclick="this.disabled=true; this.parentNode.parentNode.getElementsByTagName('textarea')[ 0 ].disabled=true;">
                                Enviar
                            </button>
                        </div>
                    </div>
                </div>
                
                <div style="background-color: rgb(0,0,0,0.010); position: absolute; bottom: 0%; left: 0%; z-index: 110; width: 100%; height: 5%; display: inline-block; margin: 0.0em;  padding: 0.0em;">
                    &nbsp;&nbsp;&nbsp;
                </div>
            </div>
        </ons-carousel-item>`;
}

const template_card_question_multiple_one = function (question) {
    return `<ons-carousel-item>
        <div  style="background-color: #efefef; padding-top: 0.50em; padding-bottom: 0.0em; margin-top: 0.0em; margin-bottom: 0.0em; margin-left: 0.0em; margin-right: 0.0em; position: absolute; width: 100%; height: 100%;">
            <div style="background-color: rgb(0,0,0,0.010); position: absolute; top:  0%; left: 0%; z-index: 110; width: 100%;  height: 10%; display: inline-block; margin: 0.0em; padding: 0.0em;"></div>
            <div style="width: 100%; height: 87%; position: relative; top: 5%; left: 0%; z-index: 100; display: flex; vertical-align: middle; align-items: center; justify-content: center; border-radius: 3px; margin: 0.0em;  padding: 0.0em; text-align: center;">   

                <div class="card card--material"  style="margin: 1.0em;  padding: 1.0em;  display: block;">
                    <div class="card__title card--material__title" style = "padding: 0.25em;">
                        ${question.question}
                    </div>
                    <div class="card__content card--material__content"></div>
                <div>
                <div style="display: inline-block; margin-top: 0.50em; margin-bottom: 0.250em; width: 100%;">
                    <form>
                        ${answers(question, "radio")}
                    </form>
                </div>

                <div style="display: block; width: 100%;">
                    <button style="margin: 1px; padding: 1.0em; border: 1px solid #198ce7; border-radius: 10px; background-color: #ffffff; width: 100%;"
                            onclick="this.disabled=true;
                                this.parentNode.parentNode.getElementsByTagName('input')[0].disabled=true;
                                this.parentNode.parentNode.getElementsByTagName('input')[1].disabled=true;
                                this.parentNode.parentNode.getElementsByTagName('input')[2].disabled=true;">
                            Enviar
                    </button>  
                </div>
            </div>
        </div>
        <div style="background-color: rgb(0,0,0,0.010); position: absolute; bottom: 0%; left: 0%; z-index: 110; width: 100%; height: 5%; display: inline-block; margin: 0.0em;  padding: 0.0em;">
            &nbsp;&nbsp;&nbsp;
        </div>    
    </ons-carousel-item>`;
}

const template_card_question_labyrinthine = function (question) {
    return `<ons-carousel-item>
        <div  style="background-color: #efefef; padding-top: 0.50em; padding-bottom: 0.0em; margin-top: 0.0em; margin-bottom: 0.0em; margin-left: 0.0em; margin-right: 0.0em; position: absolute; width: 100%; height: 100%;">
            <div style="background-color: rgb(0,0,0,0.010); position: absolute; top:  0%; left: 0%; z-index: 110; width: 100%;  height: 10%; display: inline-block; margin: 0.0em; padding: 0.0em;"></div>
            <div style="width: 100%; height: 87%; position: relative; top: 5%; left: 0%; z-index: 100; display: flex; vertical-align: middle; align-items: center; justify-content: center; border-radius: 3px; margin: 0.0em;  padding: 0.0em; text-align: center;">   

                <div class="card card--material"  style="margin: 1.0em;  padding: 1.0em;  display: block;">
                    <div class="card__title card--material__title" style = "padding: 0.25em;">
                        ${question.question}
                    </div>
                    <div class="card__content card--material__content"></div>
                <div>
                <div style="display: inline-block; margin-top: 0.50em; margin-bottom: 0.250em; width: 100%;">
                    ${labyrinthine_answers(question, "radio")}
                </div>
            </div>
        </div>
        <div style="background-color: rgb(0,0,0,0.010); position: absolute; bottom: 0%; left: 0%; z-index: 110; width: 100%; height: 5%; display: inline-block; margin: 0.0em;  padding: 0.0em;">
            &nbsp;&nbsp;&nbsp;
        </div>    
    </ons-carousel-item>`;
}

const template_card_question_multiple_choises = function (question) {
    return `<ons-carousel-item>
        <div  style="background-color: #efefef; padding-top: 0.50em; padding-bottom: 0.0em; margin-top: 0.0em; margin-bottom: 0.0em; margin-left: 0.0em; margin-right: 0.0em; position: absolute; width: 100%; height: 100%;">
            <div style="background-color: rgb(0,0,0,0.010); position: absolute; top:  0%; left: 0%; z-index: 110; width: 100%;  height: 10%; display: inline-block; margin: 0.0em; padding: 0.0em;"></div>
            <div style="width: 100%; height: 87%; position: relative; top: 5%; left: 0%; z-index: 100; display: flex; vertical-align: middle; align-items: center; justify-content: center; border-radius: 3px; margin: 0.0em;  padding: 0.0em; text-align: center;">   

                <div class="card card--material"  style="margin: 1.0em;  padding: 1.0em;  display: block;">
                    <div class="card__title card--material__title" style = "padding: 0.25em;">
                        ${question.question}
                    </div>
                    <div class="card__content card--material__content"></div>
                <div>
                <div style="display: inline-block; margin-top: 0.50em; margin-bottom: 0.250em; width: 100%;">
                    <form>
                        ${answers(question, "checkbox")}
                    </form>
                </div>

                <div style="display: block; width: 100%;">
                    <button style="margin: 1px; padding: 1.0em; border: 1px solid #198ce7; border-radius: 10px; background-color: #ffffff; width: 100%;"
                        onclick="this.disabled=true;
                            this.parentNode.parentNode.getElementsByTagName('input')[0].disabled=true;
                            this.parentNode.parentNode.getElementsByTagName('input')[1].disabled=true;
                            this.parentNode.parentNode.getElementsByTagName('input')[2].disabled=true;"> Enviar
                    </button>  
                </div>
            </div>
        </div>
        <div style="background-color: rgb(0,0,0,0.010); position: absolute; bottom: 0%; left: 0%; z-index: 110; width: 100%; height: 5%; display: inline-block; margin: 0.0em;  padding: 0.0em;">
            &nbsp;&nbsp;&nbsp;
        </div>    
        </ons-carousel-item>`;
}

const answers = function (question, typeTag) {
    const answers = ["sub_section_a", "sub_section_b", "sub_section_c", "sub_section_d", "sub_section_e", "sub_section_f", "sub_section_g", "sub_section_h"];
    let ans = "";
    if (typeTag == "radio") {
        answers.forEach(element => {
            if (question[element] != undefined) {
                ans += `<div style="border: 1px solid #198ce7; border-radius: 5px; width: 100%; margin-top: 0.25em; margin-bottom: 0.25em; padding-top: 0.50em; padding-bottom: 0.50em; padding-left: 0.0em; padding-right: 0.0em;">
                            <label class="${typeTag}" style="margin-left: 0.50em;">
                                <div class="${typeTag}__checkmark"></div>
                                    <input type="${typeTag}" class="${typeTag}__input" name="${question.id}">    
                                    ${question[element]}
                            </label>
                        </div>`;
            }
        });
    } else {
        answers.forEach(element => {
            if (question[element] != undefined) {
                ans += `<div style="border: 1px solid #198ce7; border-radius: 5px; width: 100%; margin-top: 0.25em; margin-bottom: 0.25em; padding-top: 0.50em; padding-bottom: 0.50em; padding-left: 0.0em; padding-right: 0.0em;">
                            <label class="${typeTag}" style="margin-left: 0.50em;">
                                <input type="${typeTag}" class="${typeTag}__input" name="${question.id}">    
                                <div class="${typeTag}__checkmark"></div>
                                ${question[element]}
                            </label>
                        </div>`;
            }
        });
    }
    return ans;
}

const labyrinthine_answers = function (question, typeTag) {
    const answers = ["sub_section_a", "sub_section_b", "sub_section_c", "sub_section_d", "sub_section_e", "sub_section_f", "sub_section_g", "sub_section_h"];
    let ans = "";
    answers.forEach(element => {
        if (question[element] != undefined) {
            let _href = question[element].split("|");
            ans += `<div style="border: 1px solid #198ce7; border-radius: 5px; width: 100%; margin-top: 0.25em; margin-bottom: 0.25em; padding-top: 0.50em; padding-bottom: 0.50em; padding-left: 0.0em; padding-right: 0.0em;">
                        <a href="view.html?project=1&stage=1&session=${_href[1]}&page=${_href[2]}" target="_blank">
                            <label class="${typeTag}" style="margin-left: 0.50em;">
                                ${_href[0]}
                            </label>
                        </a>
                    </div>`;
        }
    });
    return ans;
}

module.exports = {
    template_card_normal,
    template_card_score,
    template_card_progress_bar,
    template_card_question_simple,
    template_card_question_multiple_one,
    template_card_question_multiple_choises,
    template_card_question_labyrinthine
}